<template lang="pug">
v-card(v-if="Object.keys(sailorDigitalDocument).length").pa-3
  v-card-title.d-flex.justify-space-between.w-100
    div {{ $t(`document-digitalizationDocumentISC-${Object.keys(sailorDigitalDocument?.behavior)[0]}`, { id: sailorDigitalDocument?.id, typeDocument: sailorDigitalDocument.type_document_display }).toUpperCase() }}
    div.documentIconControl.pb-5
      ControlButtons(:controlButtons="controlButtons").justify-end
  v-progress-linear(v-if="isLoading" indeterminate color="cyan")
  v-divider
  v-card-text
    Form(
      v-if="sailorDigitalDocument?.behavior?.viewAddBlock"
      :sailorDigitalDocument="sailorDigitalDocument")
    Info(v-if="sailorDigitalDocument?.behavior?.viewInfoBlock" :sailorDigitalDocument="sailorDigitalDocument")
    Reject(v-if="sailorDigitalDocument?.behavior?.viewRejectBlock" :sailorDigitalDocument="sailorDigitalDocument")
    ViewPhotoList(
      v-else-if="sailorDigitalDocument?.behavior?.viewFilesBlock"
      :sailorDocument="sailorDigitalDocument"
      documentType="sailorDigitalDocument"
      )
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { viewDetailedComponent } from '@/mixins/main'
// import { checkAccess } from '@/mixins/permissions'
import { STATUSES_STATEMENT_CONSTANTS, TYPE_DOCUMENTS_CONSTANTS, STATUSES_DOCUMENT_CONSTANTS } from '@/configs/constants'
export default {
  components: {
    Info: () => import('./Info.vue'),
    Reject: () => import('./Reject.vue'),
    Form: () => import('./Form.vue'),
    ViewPhotoList: () => import('@/components/atoms/ViewPhotoList.vue'),
    ControlButtons: () => import('@/components/atoms/ControlButtons.vue')
  },
  data () {
    return {
      digitalizationDocumentISC: {},
      viewDetailedComponent,
      controlButtons: [
        {
          id: 1,
          name: 'mdi-account-arrow-right-outline',
          action: () => {
            const routerData = this.$router.resolve({ name: 'sailor', params: { id: this.sailorDigitalDocument.sailor.id } })
            window.open(routerData.href)
          },
          checkAccess: () => true,
          color: '#42627e',
          tooltip: 'documentIconControl.goToSailor',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 2,
          name: 'mdi-plus-outline',
          action: () => viewDetailedComponent(this.sailorDigitalDocument, 'viewAddBlock'),
          checkAccess: () => [STATUSES_STATEMENT_CONSTANTS.CREATED_BY_SAILOR,
            STATUSES_STATEMENT_CONSTANTS.CREATED_IN_ETI,
            STATUSES_STATEMENT_CONSTANTS.WAIT_FOR_DIGITIZATION].includes(this.sailorDigitalDocument.status_document) && !this.sailorDigitalDocument?.behavior?.viewAddBlock,
          color: 'green',
          tooltip: 'documentIconControl.createDocument',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: 'green', outlined: false }

        },
        {
          id: 3,
          name: 'mdi-information-outline',
          action: () => viewDetailedComponent(this.sailorDigitalDocument, 'viewInfoBlock'),
          color: '#42627e',
          tooltip: 'documentIconControl.info',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }

        },
        {
          id: 4,
          name: 'mdi-sync',
          action: () => this.changeStatusStatement(),
          checkAccess: () => TYPE_DOCUMENTS_CONSTANTS.EXPERIENCE_IN_SERVICE_RECORD === this.sailorDigitalDocument.type_document &&
            STATUSES_DOCUMENT_CONSTANTS.InProcessing !== this.sailorDigitalDocument.status_document,
          color: 'orange',
          tooltip: 'documentIconControl.changeStatus',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }

        },
        // {
        //   id: 5,
        //   name: 'mdi-file-document-remove-outline',
        //   action: () => viewDetailedComponent(this.sailorDigitalDocument, 'viewRejectBlock'),
        //   checkAccess: () => !this.sailorDigitalDocument?.behavior?.viewRejectBlock && [
        //     STATUSES_STATEMENT_CONSTANTS.CREATED_BY_SAILOR,
        //     STATUSES_STATEMENT_CONSTANTS.CREATED_IN_ETI,
        //     STATUSES_STATEMENT_CONSTANTS.WAIT_FOR_DIGITIZATION].includes(this.sailorDigitalDocument.status_document),
        //   color: 'red',
        //   tooltip: 'documentIconControl.reject',
        //   buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        // },
        {
          id: 6,
          name: 'mdi-image-outline',
          action: () => viewDetailedComponent(this.sailorDigitalDocument, 'viewFilesBlock'),
          color: '#42627e',
          tooltip: 'documentIconControl.viewfiles',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }

        },
        {
          id: 7,
          name: 'mdi-close',
          action: () => {
            let query = this.$route.query
            const { id } = this.$route.params
            if (this.$route.name === 'medical-statements-existing-document-info') this.$router.push({ name: 'medical-statements-existing-document', params: { id }, query })
            else if (this.$route.name === 'qualification-statements-existing-document-info') this.$router.push({ name: 'qualification-statements-existing-document', params: { id }, query })
            else if (this.$route.name === 'experience-statements-existing-document-info') this.$router.push({ name: 'experience-statements-existing-document', params: { id }, query })
            else this.$router.push({ name: 'digitalization-document-isc', query })
          },
          checkAccess: () => true,
          color: '#42627e',
          tooltip: 'documentIconControl.close',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }

        }
      ]
    }
  },
  computed: {
    ...mapState({
      sailorDigitalDocument: state => state.documents.sailorDigitalDocument,
      isLoading: state => state.documents.isLoading
    })
  },
  mounted () {
    const { statementId } = this.$route.params
    this.getData(statementId)
  },
  methods: {
    ...mapActions(['getDigitalizationDocumentISCById', 'uploadDigitalizationDocumentISCById']),
    async getData (id) {
      if (this.sailorDigitalDocument.id !== +id) await this.getDigitalizationDocumentISCById({ id })
    },
    changeStatusStatement () {
      const { statementId } = this.$route.params
      this.$swal({
        title: this.$t('Do you sure that want to take statement in work'),
        icon: 'info',
        buttons: [this.$t('no'), this.$t('yes')]
      }).then((confirm) => {
        if (confirm) {
          this.uploadDigitalizationDocumentISCById({ id: statementId, body: { status_document: STATUSES_DOCUMENT_CONSTANTS.InProcessing } })
        }
      })
    }
  }
}
</script>
